import React from "react";
import ReactDOM from 'react-dom';
import PropTypes from "prop-types";
import VideoCover from 'react-video-cover';
import Play from './Play';
import Pause from './Pause';

class Video extends React.Component {
  state = {
    playing:false,
  }
  
  handlePlayerClick = () => {
    if (!this.state.playing) {
      this.setState({playing: true})
    } else {
      this.setState({playing: false})
    }
  }
  render () {
    const getVideoSrc = width => {
       // Usage
       if(supportsVideoType('webm') === "probably") {
            // Set the video to webm
            if (width >= 1440) return this.props.video_mp4_url;
            if (width >= 1000) return this.props.video_mp4_url_large;
            if (width >= 720) return this.props.video_mp4_url_large;
            return this.props.video_mp4_url_large;
        }
        else {
            // Set the video mp4
            if (width >= 1920) return this.props.video_mp4_url;
            if (width >= 1440) return this.props.video_mp4_url_large;
            if (width >= 720) return this.props.video_mp4_url_large;
            return this.props.video_mp4_url_large;
        }
     
    };
    const isAutoPlay = width => {
      if (width >= 720) return true;
      return false;
    }
    const isPreload = width => {
      if (width >= 720) return "true";
      return "false";
    }
    
    const src = getVideoSrc(window.innerWidth);
    const autoplay = isAutoPlay(window.innerWidth);
    const videoOptions = {
        src: getVideoSrc(window.innerWidth),
        poster: this.props.portada,
        autoPlay: autoplay,
        loop:true,
        muted:true,
        preload: autoplay ? "true" : "false",
        ref: videoRef => {
          this.videoRef = videoRef;
        },
        onClick: () => {
          if (!this.state.playing) {
            this.setState({playing: true})
          } else {
            this.setState({playing: false})
          }
          if (this.videoRef && this.videoRef.paused) {
            this.videoRef.play();  
            this.state.playing=true;  
          } else if (this.videoRef) {
            this.videoRef.pause();
            this.state.playing=false;
          }
        },
        title: '',
        playsInline: true,
      };

      const divStyle = {
        position: 'absolute',
        height:'100%',
        width:'100%'
      };
      const playerStyle = {
        position: 'absolute',
        right: '30px',
        bottom: '30px',
        width: '30px',
        zIndex: '10',
        cursor: 'pointer'

      }
      function supportsVideoType(type) {
        let video;
        // Allow user to create shortcuts, i.e. just "webm"
        let formats = {
          ogg: 'video/ogg; codecs="theora"',
          h264: 'video/mp4; codecs="avc1.42E01E"',
          webm: 'video/webm; codecs="vp8, vorbis"',
          vp9: 'video/webm; codecs="vp9"',
          hls: 'application/x-mpegURL; codecs="avc1.42E01E"'
        };
        if(!video) {
          video = document.createElement('video')
        }
        return video.canPlayType(formats[type] || type);
      }
      
     

    return (
          <div style={divStyle}>
            <VideoCover
              videoOptions={videoOptions}
            />
            
            <div className="player" style={playerStyle}>
              {this.state.playing? <Pause onPlayerClick={videoOptions.onClick} /> : <Play onPlayerClick={videoOptions.onClick} />}
            </div>
          </div>
    );
  }
}

Video.propTypes = {
  portada: PropTypes.string,
  video_mp4_url: PropTypes.string,
  video_mp4_url_large: PropTypes.string,
  video_mp4_url_tablet: PropTypes.string,
  video_mp4_url_mobile: PropTypes.string,
  video_webm_url_large: PropTypes.string,
  video_webm_url_mobile: PropTypes.string
};
export default Video