import React from "react"
import ReactDOM from 'react-dom'
import PropTypes from "prop-types"
import YoutubeBackground from 'react-youtube-background'
class Banner extends React.Component {
  render () {


    const divStyle = {
      position: 'relative',
      height:'100vh'
    };
    return (
      <div style={divStyle} className="video-banner">
        <YoutubeBackground
          videoId={this.props.videoId}
        >
      </YoutubeBackground>
      </div>
      
    );
  }
}

Banner.propTypes = {
  src: PropTypes.string,
  videoId: PropTypes.string,
  playerOptions: PropTypes.object
};
export default Banner
